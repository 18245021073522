<template>
  <header class="page-header wrapper">
    <h1>
      <a href="/#/"><img class="logo" src="@/assets/logo_name.png" /></a>
    </h1>
    <nav>
      <ul class="main-nav">
        <li><a href="/#/about" class="btn">PROFILE</a></li>
        <li><a href="/#/works" class="btn">WORKS</a></li>
        <li><a href="/#/research" class="btn">RESEARCH</a></li>
        <li><a href="/#/contact" class="btn">CONTACT</a></li>
      </ul>
    </nav>
  </header>
</template>


<script>
export default {
  name: "header",
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    // routing(page) {
    //   this.$router.push('/' + page)
    // },
  },
};
</script>

<style>
.logo {
  width: 300px;
  margin-top: 14px;
}

.main-nav {
  display: flex;
  list-style: none;
  margin-top: 20px;
}

.main-nav li {
  margin-left: 15px;
}

.main-nav a {
  color: #272626;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 17px;
  border-radius: 100vh;
  width: 135px;
  height: 43px;
  font-weight: bold;
  background-color: #57d1c9;
  position: relative;
}

.main-nav a:before {
  position: absolute;
  top: -5px;
  left: -5px;
  width: 100%;
  height: 100%;
  content: "";
  border: 3px solid #3B3838;
  border-radius: 20px;
}

.main-nav a:hover:before {
  top: 0;
  left: 0;
}

.page-header {
  display: flex;
  justify-content: space-between;
}

.wrapper {
    margin: 0;
    padding: 0 3%;
}

header {
  background-color: #ffe869;
}

.main-nav .btn{
  color: #3B3838;
}

</style>