import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isOpen: false,
    isOpenWorks: false,
    paper: {
      title: null,
      img: null,
      movie: null,
      year: null,
      conf: null,
      grade: null,
      authors: null,
      abstract: null,
      link: null
    },
    works: {
      title: null,
      img: null,
      movie: null,
      year: null,
      type: null,
      explanation: null,
      link: null,
      lang: null
    }
  },
  mutations: {
    modalOpen(state, val) {
      state.isOpen = val
    },
    modalOpenWorks(state, val) {
      state.isOpenWorks = val
    },
    setPaperInfo(state, val) {
      state.paper.title = val.title
      state.paper.img = val.topImage
      state.paper.movie = val.movie
      state.paper.year = val.year
      state.paper.conf = val.confName
      state.paper.grade = val.grade
      state.paper.authors = val.authors
      state.paper.abstract = val.abstract
      state.paper.link = val.url
    },
    setWorksInfo(state, val) {
      state.works.title = val.title
      state.works.img = val.top_image
      state.works.movie = val.movie
      state.works.year = val.year
      state.works.type = val.type
      state.works.explanation = val.explanation
      state.works.link = val.link
      state.works.lang = val.language.split(',')
    }
  },
  actions: {},
  modules: {}
})