<template>
  <div>
    <div id="home" class="welcome-img">
      <img class="img" src="../assets/top_image.png" width="100%" />
    </div>
    <div class="about-this-page">
      <h1>About this page</h1>
      <div class="says">
        <img class="img" src="../assets/top_woman.svg" width="18%" />
        <div class="balloon1-left">
          <p>
            はじめまして。<br />ここは中川のポートフォリオサイトです。<br />これまでの作品や研究について紹介しています。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style>
/* ↓なぜかボタンの文字色がコンポーネントのとこだと変更できないのでここで設定する */
.main-nav a {
  color: #272626;
}

.welcome-img {
  background-color: #ffe869;
  padding-bottom: 20px;
}

.about-this-page {
  background-color: #fffbcb;
  height: 600px;
  text-align: center;
}

.about-this-page h1 {
  padding: 40px 0 20px;
  color: #3B3838;
}

.balloon1-left {
  position: relative;
  display: inline-block;
  margin: 60px 0 0 40px;
  padding: 27px 30px;
  min-width: 120px;
  max-width: 100%;
  height: 210px;
  color: #555;
  font-size: 14px;
  background: #FFE969;
  line-height: 50px;
  border-radius: 30px;
  text-align: left;
}

.balloon1-left:before {
  content: "";
  position: absolute;
  top: 30%;
  left: -30px;
  margin-top: -15px;
  border: 15px solid transparent;
  border-right: 15px solid #FFE969;
}

.balloon1-left p {
  margin: 0;
  padding: 0;
}

.says .img {
  margin-bottom: -50px;
  margin-right: -20px;
  max-width: 200px;
}

.says {
  display: flex;
  justify-content: center;
}
</style>