<template>
  <v-app>
    <v-main>
      <Header_component></Header_component>
      <router-view/>
      <Footer_component></Footer_component>
    </v-main>
  </v-app>
</template>

<script>
import Header_component from './components/Header.vue'
import Footer_component from './components/Footer.vue'

export default {
  name: 'App',

  data: () => ({
    //
  }),

  components: {
    Header_component,
    Footer_component
  }
};
</script>

<style>
</style>